import React, { useEffect, useState } from "react";
import { useToast, useTheme } from "@chakra-ui/react";
import { get } from "lodash";
import moment from "moment";
import { Form as FinalForm } from "react-final-form";
import { useLocation } from "@reach/router";

import { get as GET } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import Heading from "../../base/Heading";
import Spinner from "../../base/Spinner";
import Table from "../../Table";
import { TOTAL_JUMP_PAGES } from "../../../utilities/constants";
import dashboardConstants from "../../../utilities/dashboard-constants";
import responsiveCss from "../../../utilities/responsiveCss";
import generateSearchString from "../../../utilities/generate-search-string";
import DashboardPaginator from "../../CommonComponents/DashboardPaginator";
import searchMultiple from "../../../utilities/searchMultiple";

const AvailableSimsListingPage = () => {
  const theme = useTheme();
  const toast = useToast();
  const location = useLocation();
  const [availableSimsData, setAvailableSimsData] = useState({});
  const [sample, setSample] = useState(true);
  const [searchValue, setSearchValue] = useState({});
  const [loading, setLoading] = useState(true);
  const sampleToggle = () => {
    setSample(!sample);
  };
  const { filters, pageCriteria } = dashboardConstants.getCriteria({
    search: location.search,
    type: "availableSims",
  });
  const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  );
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = await GET(`/available-sims?${generateSearchString(query)}`);
      if (data) {
        const filteredAvailableSimData = searchValue
          ? searchMultiple(get(data, "data", []), searchValue)
          : get(data, "data", []);

        setAvailableSimsData({ ...data, data: filteredAvailableSimData });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location, sample]);
  const tableHeader = [
    { label: "#", value: "sim_id" },
    { label: "ICC", value: "icc" },
    { label: "ISDN", value: "isdn" },
    { label: "Compañía", value: "company" },
    { label: "Estatus", value: "status" },
    { label: "Fecha de creación", value: "creationDate" },
  ];
  const pageTitle = "Listado de SIM disponibles";
  // const loading = false;
  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  return (
    <Route isPrivate layout="admin">
      <Section sx={{ mx: "auto" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Heading {...theme.variant.heading}>{pageTitle}</Heading>
        </Box>
        <Box>
          <FinalForm
            onSubmit={async () => {}}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", null, "row"],
                    mb: 8,
                  }}
                >
                  <Box
                    as="input"
                    id="icc"
                    name="icc"
                    onChange={(e) => {
                      setSearchValue({ ...searchValue, icc: e.target.value });
                      sampleToggle();
                    }}
                    placeholder="ICC"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as="input"
                    id="isdn"
                    name="isdn"
                    onChange={(e) => {
                      setSearchValue({
                        ...searchValue,
                        isdn: e.target.value,
                      });
                      sampleToggle();
                    }}
                    placeholder="ISDN"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                </Box>
              </form>
            )}
          />
        </Box>
        <Box>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Table
                header={tableHeader}
                renderEmpty="Sin datos"
                rows={get(availableSimsData, "data", []).map((sim) => [
                  get(sim, "sim_id", "-"),
                  get(sim, "icc", "-"),
                  get(sim, "isdn", "-"),
                  get(sim, "company", "-"),
                  get(sim, "status", "-"),
                  get(sim, "purchased")
                    ? moment(get(sim, "created_at")).format("LLLL")
                    : "-",
                ])}
                tableSx={{
                  borderCollapse: "collapse",
                  overflow: "visible",
                }}
              />
              {availableSimsData.total > 0 && (
                <DashboardPaginator
                  buttonSx={{
                    color: "primary",
                  }}
                  enableJumpToPage
                  filters={filters}
                  pageCriteria={pageCriteria}
                  pageTotal={get(availableSimsData, "data", []).length}
                  total={get(availableSimsData, "total")}
                  totalJumpToPages={totalJumpToPages}
                />
              )}
            </>
          )}
        </Box>
      </Section>
    </Route>
  );
};
export default AvailableSimsListingPage;
