import React from "react";
import { Router } from "@reach/router";

import AvailableSimsListingPage from "../components/PageComponents/AvailableSimsListingPage";

const AvailableSims = (props) => (
  <Router>
    <AvailableSimsListingPage {...props} path="/availableSims" />
  </Router>
);

export default AvailableSims;
